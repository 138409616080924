//
// authentication.scss
//

// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}
// h6 {
//     width: 100%;
//     text-align: center;
//     border-bottom: 1px solid #ced4da;
//     line-height: 0.1em;
//     margin: 10px 0 20px;
//  }

//  h6 span {
//      background:#fff;
//      padding:0 10px;
//  }

.logo-fixed {
    img {
        width: 150px;
    }
    display: flex;
    align-items: center;
    padding-left: 4%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    height: 70px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
}
.about-page-auth {
    padding-top: 85px;
    .accordian-container {
        .card {
            box-shadow: none;
            .card-header {
                padding: 0;
                background-color: #fff;
            }
        }
    }
}
.custom-google-btn,
.custom-linkdn-btn {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 15px;
}
.custom-google-btn,
.custom-linkdn-btn {
    & a {
        color: $text-black;
    }
}
.input-group-append {
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
    z-index: 111 !important;
}
.signup-description {
    font-family: Arial, Helvetica, sans-serif;

    padding-top: 1.5rem;
    margin-left: 20%;
    padding-bottom: 20px;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: #111111;
}

.mobileDevice {
    .custom-signup-description {
        padding-bottom: 20px;
        color: $light-text-color;
        margin-left: 0%;
    }
}

.pass-err-desc {
    color: $light-text-color;
}
.pw-match-success {
    color: #34c38f;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
}

.account-pages .left-section {
    height: 100vh;
    background-color: #fff;
    width: 55%;
    display: flex;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../images/ZunavishBackGround.png");
    justify-content: center;
}
.account-pages .left-section-changed {
    height: 100vh;
    background-color: #fff;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: start;
}
.light-text {
    color: #174731;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 300;
}
.text-sub-heading {
    color: #174731;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font: size 16px;
}
.account-pages .right-section {
    padding: 20px;
    height: 100vh;
    width: 45%;
    background-repeat: no-repeat;
    background: url("../../../images/signup.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-pages {
    .login-form {
        max-width: 400px;
        margin-left: 20%;
    }
    .login-heading {
        margin-left: 20%;
        color: #174731;
        font-weight: 700;
    }
    .text-container {
        position: absolute;
        top: 80%;

        margin-left: 3%;
    }
    .company-text-bold {
        img {
            width: 295px;
        }
    }
    .banner-img {
        background: no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .form-group {
        ul {
            li {
                img {
                    width: 15px;
                }
            }
        }
    }
}

//Upload Resume
.upload-file {
    & .dropzone {
        min-height: 150px;
        cursor: pointer;
        border: 2px dashed #e5e5e5;
        border-radius: 4px;
        box-sizing: border-box;
        border-radius: 4px;
        & .dz-message {
            padding: 10px;
        }
        & .upload-resume-text {
            font-weight: 700;
            color: $dark-green;
            text-decoration-line: underline;
        }
    }
}

//Wizrad

.twitter-bs-wizard {
    & .twitter-bs-wizard-nav {
        background-color: transparent;
        & .nav-item {
            & .nav-link {
                & .step-number {
                    width: 22px;
                    height: 22px;
                    background-image: url("../../../images/wizard_dot.png");
                    background-size: cover;
                    color: transparent;
                    border: none;
                    background-color: transparent;
                    //border: 1px solid $text-black;
                    // color: $text-black;;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 54%;
                        width: 92%;
                        top: 12px;
                        height: 2px;
                        color: #fff;
                        background-color: transparent;
                        border-top: 1px solid $dark-green;
                    }
                    &.pd-width,
                    &.ed-width,
                    &.empd-width {
                        width: 22px;
                        height: 22px;
                        background-image: url("../../../images/selected_wizard_dot.png");
                        background-size: cover;
                        color: transparent;
                        border: none;
                    }
                    // &.solid-line-circle{
                    //     background-image: url("../../../images/selected_wizard_dot.png");
                    //     background-size: cover;
                    //     color: transparent;
                    // }
                }
                &:hover {
                    color: $text-black !important;
                }
                padding: 0px;
                position: relative;
            }
            &:last-child .step-number::before {
                display: none;
            }
        }
    }
}

.tab-heading-light {
    color: #e6e6e6;
}

.personal-details .cust-radio-label {
    padding-right: 150px;
}
.personal-details .cust-select {
    height: auto;
    border: none;
    width: 40%;
    border-radius: 0px;
    border-bottom: 1px solid #ced4da;
    padding: 0px 10px;
}
.added-row {
    //border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 20px 15px;
    color: $dark-green;
    background: #447e632b;
    border-radius: 4px;
    & .solid-grey-circle {
        width: 39px;
        height: 39px;
        border-radius: 240px;
        background-color: #efefef;
    }
}
.green-back {
    cursor: pointer;
    border: 1px solid #e5e5e5;

    color: $dark-green;
    background: #447e632b;
    & .solid-grey-circle {
        width: 39px;
        height: 39px;

        background-color: #efefef;
    }
}
.react-datepicker-wrapper .form-control[readonly] {
    opacity: 0.7;
}
textarea {
    resize: none;
}
// .phone-number-input{ padding-left: 66px;}
.phone-number-select {
    height: 100% !important;
    padding: 5px 0px;
    width: 90%;
    &.form-control:focus {
        background-color: transparent;
    }
}
.add-other {
    color: $text-black;
    text-decoration-line: underline;
}

.more-about-yourself textarea.form-control,
.work-related textarea.form-control,
.job-relatedf textarea.form-control {
    height: 45px;
}
//Form Input

//form error
.account-pages .form-control.is-invalid {
    background-image: none;
    &:focus {
        box-shadow: none;
        border-bottom-color: #f46a6a;
        background-image: none;
    }
}
// .account-pages .form-group .form-control{
//     border: none;
//     border-bottom: 1px solid $border-bottom-color;
//     padding-left: 0rem;
//     border-radius: 0rem;
//     padding-top: 0rem;
//     padding-bottom: 0rem;
// }

.auth-form-submit {
    padding-top: 1rem;
    padding-bottom: 1.5rem !important;
}
.auth-switch {
    padding-top: 0.5rem !important;
}

.educational-details .select-container .css-1hb7zxy-IndicatorsContainer,
.employment-details .select-container .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.educational-details .select-container .css-tlfecz-indicatorContainer,
.employment-details .select-container .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%);
}
.form-control:disabled,
.form-control[readonly] {
    opacity: 0.3;
}
@media (max-width: 898px) {
    .account-pages .left-section {
        padding: 20px 20px;
    }
}
.cust-radio-label {
    padding-right: 50px;
}
