.resume-container {
  .tab-content {
    padding: 15px !important;
    background-color: $white;
    margin-top: 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    top: 8.3%;
    border-radius: 7px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 70px;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    font-size: 9px;
    justify-content: center;
    height: 70px;
    text-align: center;
  }
}

.choose-theme {
  padding: 15px;
  background-color: $white;
  border: 1px solid #eeeeee;
  border-radius: 4px;

  .theme-block-container {
    .theme-block {
      cursor: pointer;
      padding: 10px;
      padding-left: 0px;
      display: inline-block;

      .overlay {
        opacity: 0.5;
      }
    }
  }
}
.modal-open .modal{
  overflow-x: auto;
  overflow-y: auto;
}

.resume-modal {
  .modal-dialog {
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .modal {
    overflow-x: auto;
    overflow-y: auto;
  }


  .modal-content {
    background-color: #ffffff00;
    border: none;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
}

.theme-2 {
  font-family: Roboto;
  overflow: hidden;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 100px;
  background-color: #ffffff;
  min-height: 842px;

  .right-section {
    border-left: 1px solid #e2e2e2;
    padding-top: 0px;

    .content {
      padding-left: 16px;
      display: flex;
      flex-direction: row;
      font-weight: 500;
    }

    .data-points {
      padding-top: 2px;
      padding-bottom: 2px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 152.3%;
      /* or 15px */
      width: 100%;

      color: #000000;
    }
  }

  .heading {
    font-weight: 500;
    padding-bottom: 16px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 112.3%;
  }

  .year {
    line-height: 1.5rem;
    padding-left: 2px;
    font-size: 10px;
    font-weight: 500;
  }

  .title {
    line-height: 1.5rem;
    color: #026da9;
    font-weight: bold;
    padding-bottom: 2px;
    font-size: 10px;

    font-family: Roboto;
  }

  .des {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 152.3%;
    /* or 15px */

    color: #000000;
  }

  .bio {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 152.3%;
  }

  .side-bio {
    background-color: #bf9100;
    width: 27px !important;
    padding: 0;
  }

  .photo-background {
    background-color: #bf9100;
    // height: 200px;
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: center;

    // position: absolute;
    width: 130px;
    height: 145px;
    // left: 40px;
    // top: 0px;
  }

  .main-heading-name {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    color: #000000;

    .first-name {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 24px;
      line-height: 2rem;
    }

    .last-name {
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 24px;
      line-height: 2rem;
    }

    .designation {
      padding-top: 24px;
      font-family: Roboto;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .contact-details {
    div {
      padding: 4px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 152.3%;
    }

    .data-points {
      display: flex;
      align-items: center;
    }
  }

  .left-section {
    float: left;
    width: 30%;
    background-color: #003d74;
    color: $white;
    padding: 15px;

    .personal-info,
    .skill-info {
      .heading {
        background-color: #05294a;
        margin-left: -15px;
        margin-right: -15px;
        padding: 5px 15px;
        font-size: 16px;
      }
    }

    .skill-info {
      .progress {
        background-color: #05294a;
        border-radius: 0px;

        .bg-primary {
          background-color: $white !important;
        }
      }
    }
  }
}

.resume-heading {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  align-self: center;
  vertical-align: middle;
}

.theme-1 {
  overflow: hidden;
  font-family: Roboto;
  width: 100%;
  margin-right: 80px;
  display: flex;
  min-height: 842px;


  .left-section {
    float: left;
    width: 40%;
    background-color: #003d74;
    color: $white;
    padding: 15px;
    height: 100%;

    .personal-info,
    .skill-info {
      .heading {
        background-color: #05294a;
        margin-left: -15px;
        margin-right: -15px;
        padding: 5px 15px;
        font-size: 16px;
      }
    }

    .skill-info {
      .progress {
        background-color: #05294a;
        border-radius: 0px;

        .bg-primary {
          background-color: $white !important;
        }
      }
    }
  }

  .right-section {
    float: right;
    width: 60%;
    height: 100%;
    background-color: $white;
    padding: 15px;

    .blue-heading {
      color: #05294a;
      border-top: 2px solid #eeee;
      border-bottom: 2px solid #eeee;
      padding: 3px 0px;
    }
  }
}

.resume-heading {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125rem;
  align-self: center;
  vertical-align: middle;
}

.theme-3 {
  $primaryColor: #7030a0;
  $textColor: white;
  $primaryFont: "Open Sans";

  width: 100%;
  min-height: 852px;
  padding-left: 50px;
  padding-right: 40px;
  // padding-bottom: 100px;

  @mixin listStyle {
    ul {
      margin-top: 15px;
      // margin-left: 7px;
      margin-left: -3em;
      font-size: 9px;
      line-height: 15.51px;

      li {
        color: $primaryColor;

        span {
          font-weight: 400;
          color: black;
        }
      }
    }
  }

  .banner {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // align-items: center;
    margin-top: 55px;
    background-color: $primaryColor;
    height: 67px;
    color: $textColor;

    .image {
      position: absolute;
      width: 117px;
      height: 117px;
      left: 150px;
      top: 70px;
    }

    .user-profile-img {
      width: 115px;
      height: 115px;
      display: flex;
      justify-content: center;
      align-content: center;
      // padding: 4px;
      // border: 1px solid #e5e5e5;

      border: 2px solid $primaryColor;
    }

    .main-heading-name {
      // left: 27%;
      margin-left: 254px;
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // padding: 20px;
      // width: auto;
      justify-content: center;
      padding-left: 10px;

      .first-name {
        // display: inline-flex;
        margin-top: 12px;
        font-family: $primaryFont;
        font-weight: normal;
        font-size: 20px;
        line-height: 22.46px;
      }

      .designation {
        // position: relative;
        padding-top: 4px;
        font-family: $primaryFont;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .content {
    margin-top: 24px;
    display: flex;
    font-family: $primaryFont;
    flex-wrap: wrap;
  }

  .left-section {
    margin-left: 40px;
    margin-top: 24px;
    // display: flex;
    width: 30%;
    float: left;
    font-size: 10px;
    font-weight: bold;
    line-height: 15.23px;

    .contact-details {
      line-height: 14px;

      p {
        margin-top: 9px;
        margin-bottom: 0;
        font-weight: normal;
        word-wrap: break-word;
      }

      i {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .academic-section {
      margin-top: 18px;
    }

    .academic-details {
      font-weight: 400;
      margin-top: 10px;
      line-height: 15.5px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      p {
        font-size: 9px;
        margin: 0px;
      }

      span {
        margin-left: 2px;
        font-weight: 600;
        line-height: 13.71px;
        font-size: 9px;
        // text-align: right;
      }
    }

    .skills-section {
      margin-top: 18px;
      @include listStyle;

      ul {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .references-section {
      margin-top: 18px;
      font-size: 10px;
      font-weight: bold;

      span {
        margin-left: 20px;
        font-size: 9px;
        font-weight: normal;
      }
    }
  }

  .line {
    position: absolute;
    top: 420px;
    left: 40%;
    border-left: 1px solid #e2e2e2;
    min-height: 600px;
  }

  .right-section {
    position: absolute;
    left: 350px;
    width: 60%;
    font-size: 10px;
    font-weight: 700;
    line-height: 11.23px;
    margin-top: 24px;
    margin-left: 10px;

    .bio-section {
      margin-right: 24px;

      p {
        margin: 10px 0px;
        font-weight: normal;
        width:90%;
        font-size: 9px;
        line-height: 13.71px;
      }
    }

    .employment-section {
      margin-top: 10px;
      margin-right: 24px;

      .employment-details {
        // margin-bottom: 18px;
        font-size: 9px;
        line-height: 13.71px;
        width: 100%;
        display: grid;
        margin: 10px 0px;
        grid-template-columns: 1fr 1fr;

        p {
          width: 50%;
          font-size: 9px;
        }

        span {
          margin-left: 2px;
          font-weight: 600;
          line-height: 13.71px;
          font-size: 9px;
        }

        p {
          br {
            margin-bottom: 4px;
          }

          font-weight: 400;
        }
      }
    }

    .courses-section {
      margin-top: 10px;

      ul {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: -1.5rem;
        font-size: 9px;
        line-height: 13.71px;

        li {
          margin-left: 5px;
          align-items: center;
          color: $primaryColor;

          span {
            font-weight: 400;
            color: black;
          }
        }
      }
    }

    .certifications-section {
      margin-top: 10px;
      @extend .courses-section;
    }
  }
}

.theme-4 {
  $primaryColor: #404040;
  $secondaryColor: #e9e9e9;
  $primaryFont: "Open Sans";
  overflow: hidden;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 100px;
  min-height: 852px;

  @mixin para {
    font-weight: 400;
    margin-top: 12px;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 13.71px;
    @content;
  }

  @mixin listStyle {
    ul {
      margin-top: 19px;
      margin-left: -3.5em;
      font-weight: 600;
      font-size: 9px;
      letter-spacing: normal;
      color: $secondaryColor;
      line-height: 13.71px;

      // font-weight: bold;
      span {
        color: black;
        // font-weight: 400;
      }
    }
  }

  * {
    margin: 0;
    font-family: $primaryFont;
  }

  body {
    border-radius: 10px;
    width: 595px;
    min-height: 842px;
  }

  .header {
    //   width: 100%;
    //   overflow: auto;
    height: 100px;
    background-color: $primaryColor;
  }

  .main-heading-name {
    left: 279px;
    color: white;
    // position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // padding: 20px;
    // width: 30%;
    justify-content: center;

    .first-name {
      // display: inline-flex;

      position: absolute;
      width: 380px;
      // height: 100%;
      margin-left: 250px;
      margin-top: 22px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 112.3%;
    }
  }

  .designation {
    position: relative;
    // top: 100%;
    left: 255px;
    margin-top: 60px;
    padding-top: 4px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    opacity: 0.6;
  }

  .content {
    display: flex;
    flex-basis: auto;

    font-weight: 700;
    font-size: 10px;
    position: relative;
    letter-spacing: 0.1em;

    .about-section {
      position: absolute;
      top: -100px;
      width: 189px;
      min-height: 785.69px;
      margin: 24px 0 0 24px;
      background-color: $secondaryColor;
      border-radius: 3px;

      color: #000000;

      img {
        width: 120px;
        width: 120px;
        border-radius: 50%;
        margin: 16px 35px 0 34px;
      }

      .contact-section {
        margin: 24px 119px 0 16px;
        width: 100%;

        h1 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 112.3%;
          /* or 11px */

          letter-spacing: 0.1em;
          margin-bottom: 10px;
        }

        p {
          @include para;

          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 9px;
          line-height: 152.3%;
          margin: 0px;
          padding-right: 25px;
          width: 100%;
        }
      }

      .education-section {
        margin: 24px 105px 0 16px;
        @extend .contact-section;
      }

      .skills-section {
        margin: 15px 33px 44.69px 16px;
        letter-spacing: normal;

        h1 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 112.3%;
          /* or 11px */

          letter-spacing: 0.1em;
          margin-bottom: 10px;
        }

        ul {
          margin-top: 10px;
          margin-left: -3.5em;
          font-weight: 400;
          font-size: 9px;

          li {
            line-height: 14px;
            margin-bottom: 4px;
          }
        }
      }
    }

    .line {
      margin-top: 77px;
      margin-left: 237px;
      margin-right: 20px;
      border-left: 1px solid #e2e2e2;
      min-height: 612px;
    }

    .right-section {
      margin: 46px 0 0 0;
      line-height: 11.23px;

      h1 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 112.3%;
        /* or 11px */

        letter-spacing: 0.1em;
        margin-bottom: 10px;
      }

      p {
        margin-right: 24px;
        @include para;
      }
    }

    .employment-section {
      margin-top: 32px;

      div {
        @include para;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 152.3%;
        margin: 0px;
      }
    }

    .courses-section {
      margin-top: 44px;
      @include listStyle;

      ul {
        margin-top: 10px;

        li {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 152.3%;
          /* identical to box height, or 14px */

          color: #000000;
        }
      }
    }

    .certifications-section {
      margin-top: 44px;
      @include listStyle;

      ul {
        margin-top: 10px;

        li {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 152.3%;
          /* identical to box height, or 14px */

          color: #000000;
        }
      }
    }
  }
}

.theme-5 {
  $primaryColor: #4192b5;
  $primaryFont: "Open Sans";
  color: #000000;
  min-height: 852px;

  h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 112.3%;
    /* or 11px */

    margin-bottom: 5px;
    color: #4192b5;
  }

  @mixin para {
    p {
      font-weight: 400;
      margin-top: 8px;
      color: black;
      font-size: 9px;
      line-height: 13.71px;
    }
  }

  @mixin line {
    width: 330px;
    height: 1px;
    background-color: rgba(65, 146, 181, 0.5);
  }

  * {
    margin: 0;
    font-family: $primaryFont;
  }

  body {
    width: 595px;
    min-height: 842px;
    border-radius: 10px;
  }

  .content {
    display: flex;
    // flex-wrap: wrap;
    min-height: 842px;
    overflow: hidden;
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 100px;

    // overflow: auto;

    .left-section {
      width: 212px;
      height: 842px;
      color: $primaryColor;
      font-size: 10px;
      font-weight: 700;
      line-height: 11.23px;
      background-color: #ebe8e9;

      img {
        width: 162px;
        height: 162px;
        border-radius: 50%;
        border: 4px solid #96b7d1;
        margin: 97px 0 0 16px;
      }

      .bio-section {
        margin: 24px 10px 0 24px;

        // line-height: 112.3%;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 9px;
          line-height: 152.3%;
          color: #000000;
          /* or 14px */
          margin-bottom: 4px;
        }
      }

      .contact-section {
        margin: 24px 0 0 24px;
        @include para;

        span {
          color: #ba8807;
        }
      }
    }

    .right-section {
      // position: absolute;
      margin: 86px 0 0 40px;
      left: 242px;
      color: $primaryColor;
      font-weight: 700;
      line-height: 112.3%;
      width: 70%;

      .line {
        @include line;
      }

      .title-section {
        font-size: 40px;
        color: black;
        text-transform: uppercase;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        width: 100%;
        line-height: 112.3%;

        p {
          margin-top: 11px;

          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          /* identical to box height */

          letter-spacing: 0.1em;
          text-transform: uppercase;

          opacity: 0.6;
        }
      }

      .education-section,
      .work-section {
        margin-top: 32px;
        @include para;
      }

      .skills-section,
      .certifications-section,
      .courses-section {
        margin-top: 36px;
        @include para;

        ul {
          margin-left: -3em;
          list-style: none;
          letter-spacing: normal;
          color: black;

          li {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 152.3%;
          }
        }
      }
    }
  }
}