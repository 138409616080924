
.job-container{
    width: 80%;
    margin: 0 auto;  
    padding-right: 10px;
}
.jobs-cont{
    margin-top: 70px;
}

.top-container{
    background-color: white;
    border-radius: 4px;
    .ux-designer{
        color: white;
        padding-top: 1.5em;
        padding-left: 6em;
        background: linear-gradient(270.31deg, #98D9BB 0.51%, rgba(255, 255, 255, 0) 68.45%), #447E63;
        height: 70px;
        font-size: 20px;
        border-top-left-radius:4px;
        border-top-right-radius:4px;
    }
    .shift-image{
        margin-top: -38px;
    }
    .company-info{
        min-height: 60px;
        margin-top: -2.2em;
        margin-left: 2.5em;
    }
    .button-wid-150{
        width: 150px;
    }
    // .loc{
    //     img{
    //        // width:27px;
    //     }
    // }
    
}


.job-side-container{
    // height: 81vh;
    max-height: 82vh;
    &.card{
        box-shadow: none;
        border: 1px solid #e5e5e5;
    }
    ul{
        overflow-y: auto;
        max-height: 82vh;
        overflow-x: hidden;
        &::-webkit-scrollbar {height: 10px;width: 5px}
        &::-webkit-scrollbar-thumb {background-color: rgba(50,50,50,.25);    border: 1px solid transparent;    border-radius: 10px;
            background-clip: padding-box
        }
    }
    .list-item-container{
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;

        &:hover{ 
            cursor: pointer;   
            background-color: #447e633d;
            border-bottom: none;
        }
        &.selected{
            background-color: #447e633d;
            border-bottom: none;
        }
        // .list-item{
        //     border-bottom: 1px solid #e5e5e5;
        // }
        &:last-child{
            border-bottom: none;
            padding-bottom: 75px;
        }
    }
    .no-result{
        padding: 20px;
        background-color: #447e633d;
        font-size: 18px;
    }
}
.list-item{
    font-family: Helvetica;
    font-weight: 300;
}
.skills-card{
    width: 220px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
}
.gray-round-box-s{
    background-color: rgb(241, 236, 236);
    border-radius: 10px;
    padding: 0 5px;
}
.f-gray{
    opacity: 0.7;
}
.apply-btn{
    img{
        width: 16px;
    }
}
.border-bottom-dark{
    border-bottom: 1px solid #e5e5ee;
}
.job-detail-card.card{
    box-shadow: none;
    border: 1px solid #e5e5e5;
    // .card-body{
    //     p{
    //         border-bottom: 1px solid #e5e5e5;
    //     }
    // }
}
.green-status-text{
    padding: 5px 10px;
    background-color: #74B496;
    color:$white;
    font-size: 11px;
    font-weight: 200;
    border-radius: 15px;
    &.reject{
        background-color:#f46a6a;
    }
}
.apply-job-modal .form-control.is-invalid{
   background-image: none;
}
.dashboard-page .no-result{
    padding: 20px;
    background-color: #447e633d;
    font-size: 14px;
}


