.user-profile{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid #e5e5e5;
}
.no-hover{
    text-decoration: none;
    color: rgb(8, 43, 8);
}

.image-profile{
    display: flex;
    justify-content: center;
}

.f-20{
    font-size: 20px;
}
.f-12{
    font-size: 12px;
}
.f-18{
    font-size: 1.125rem;
}
.f-16{
    font-size: 16px;
}


.small-blurred-text{
    font-size: 11px;
    color: black;
}
.f-w{
    color: white;
}
.f-g{
    color:#447E63;
}
.f-w-200{
    font-weight: 500;
}
.f-w-100{
    font-weight: 300;
}
.f-w-300{
    font-weight: 300;
}
.round-circle{
    width:20px;
    height: 20px;
    border-right: 2px solid rgb(6, 71, 6);
    border-top: 2px solid rgb(6, 71, 6);
    border-radius: 20px;
    padding: 0px 1px;
    background: #74B496;
}

.active-applications ul li{
    list-style: none;
    margin: 0;
    
}
.active-applications ul{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    margin: 0;
    padding:0px;
}
.active-applications{
    background: #FFFFFF;

}
.profile-section{
    background: #FFFFFF;
    padding: 10px ;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}
.last-section{
    background: #FFFFFF;
    padding: 1.25rem 0px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding-bottom: 0.8em;
}
body[data-layout="horizontal"] {
    .page-content.dashboard-page{
        padding-top:24px;
        .container-fluid{
            max-width: 100%;
        }
        .margin-right-35{
            margin-right: 35px;
        }
    } 
}  
.active_app_link{
    display: block;
    &:hover{
        background-color: #447e633d;
    }
}
