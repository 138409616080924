.sidenav-box{
    // width: 310px;
    // background-color: #F8F8Fb;
}
.dot-bor{
    border: 1px dotted black;
    border-radius: 20px;
}
.needsclick{
    outline: none !important;
    cursor: pointer;
}
.f-14-roboto{
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
}
.f-12-blurred-roboto{
    opacity: 0.7;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 16px;
letter-spacing: 0em;
}
.basic-details{
    border-bottom: 1px solid #e5e5ee;
}