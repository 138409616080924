.box-personal{
    min-width: 340px;
    background: #FFFFFF;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    min-height: 105px;
}
.btn-sp{
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
}
.top-border-box-g{
    border: #dddddd;
    padding: 20px;
    background: #447e633d;
    border-radius: 4px;
}
.bg-verification-modal .modal-body{
    padding-right: 55px;
    padding-left: 55px;
    .bg-uplaod-btn{
        padding: 10px;
        & img{
            width: 14px;
        }
    }
    .pf-margin{
        margin-top: 21px;
    }
    .company-logo-img{
        width: 55px;
        position: relative;
        img{
            width: 40px;
            height: 40px;
            border-radius: 4px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        }
        .img-close{
            cursor: pointer;
            width: 15px;
            height: 15px;
            background: #EB5757;
            border-radius: 100%;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            position: absolute;
            right: 8px;
            top: -5px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
}
