.account-setting{
    .card{
        box-shadow: none;
        border: 1px solid #e5e5e5;
    }
    .form-control.is-invalid{
        background-image: none;
    }
    .form-group{
        ul{
            li{
                img{
                    width: 15px;
                }
            }
        }
    }

    .top-container{
        background-color: white;
        border-radius: 4px;
        .ux-designer{
            height: 55px;
            color: white;
            padding-left: 5.5em;
            padding-top: 1.4em;
            background: linear-gradient(270.31deg, #98D9BB 0.51%, rgba(255, 255, 255, 0) 68.45%), #447E63;
            font-size: 20px;
            border-top-left-radius:4px;
            border-top-right-radius:4px;
            padding-bottom: 2.7%;
        }
        .shift-image{
            margin-top: -38px;
        }
        .company-info{
            margin-top: -2em;
            margin-left: 8em;
            min-height: 45px;
        }
        
    }
}
