$dark-green: #213d30;
$card-background-color: #f3f3f3;
$profile-border-color: #dde1df;
$light-text-color: #6f6f6f;
$text-black: #000000;
$border-bottom-color: #eeeeee;
$btn-grey-background: #c4c4c4;
$card-company-text-color: #111111;
$light-green: #74b496;

.label-required {
  color: #f46a6a;
}

.form-group label {
  color: $light-text-color;
}
.cut-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.word-wrap {
  overflow-wrap: break-word;
}

// .form-group input,.form-group select{
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #E0E0E0;
//     padding: 0px 0px;
//     height: auto;
// }
.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.scc {
  white-space: nowrap;
}

.text-color-light {
  color: $light-text-color;
}

.form-group input::placeholder {
  color: $light-text-color;
  font-weight: 300;
}

.font-weight-200 {
  font-weight: 200;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.text-muted-1 {
  opacity: 0.5;
}

//button background color
.bg-green {
  background-color: #213d30;
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:focus {
    box-shadow: none;
  }
}

.font-light-green {
  color: #74b496;
}

.font-dark-green {
  color: #213d30;
}

.bg-cancel {
  background: #ffffff;
  border: 1px solid #74b496;
  border-radius: 4px;
  color: #74b496;

  &:hover {
    color: #74b496;
  }

  &:focus {
    box-shadow: none;
  }
}

.bg-light-green {
  background-color: #74b496;
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:focus {
    box-shadow: none;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #74b496;
}

.bg-grey {
  background-color: $btn-grey-background;
  color: $text-black;

  &:hover {
    color: $text-black;
  }

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-outline-shadow {
  color: #213d30;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &:hover {
    color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  &:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}

.btn-outline-green {
  color: #213d30;
  border-color: #213d30;

  &:hover {
    color: #fff;
    background-color: #213d30;
    border-color: #213d30;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-light-green {
  color: #74b496;
  border-color: #74b496;

  &:hover {
    color: #fff;
    background-color: #74b496;
    border-color: #74b496;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-grey {
  color: $text-black;
  border-color: #4f4f4f;

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.1;
  }
}

//text color
.green-text-color {
  color: #213d30 !important;

  &:hover {
    color: #213d30 !important;
  }
}

.light-green-text-color {
  color: #447e63;

  &:hover {
    color: #447e63;
  }
}

//Date Css
.cust-date-icon {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 18px;
  cursor: pointer;
}

.react-datepicker__input-container input::placeholder {
  color: #e0e0e0;
}

//Toast Notification
#toast-container > .toast-error {
  background-image: none !important;
}

.cursor-pointer {
  cursor: pointer;
  white-space: nowrap;
}

///Body

body[data-layout-size="boxed"] {
  background-color: #f6f6f6;
  color: $dark-green;
}

//page-content padding

body[data-layout="horizontal"] {
  .page-content {
    padding-top: #{$grid-gutter-width};
    color: $dark-green;
    // background-color: $white;
  }
}

//top header
body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  background-color: $dark-green;
}

body[data-layout="horizontal"][data-layout-size="boxed"] #page-topbar {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
}

.app-search {
  width: 70%;
}

body[data-layout="horizontal"][data-topbar="light"] .app-search .form-control {
  border: none;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f3f3f9;
  box-shadow: none;
  border-radius: 6px;
}

.app-search span {
  left: unset;
  right: 15px;
  line-height: 30px;
}

body[data-layout="horizontal"][data-topbar="light"] a.header-item {
  &:hover,
  &.active {
    color: $header-dark-item-color;
    border-bottom: 3px solid $header-dark-item-color;
    // font-weight: bold;
    text-shadow: 0 0 0.65px $header-dark-item-color,
      0 0 0.65px $header-dark-item-color;
  }

  padding-bottom: 22px;
}

body[data-layout="horizontal"][data-topbar="light"] .app-search span,
body[data-layout="horizontal"][data-topbar="light"]
  .app-search
  input.form-control::-webkit-input-placeholder {
  color: #74788d;
}

.navbar-header .logo.logo-dark {
  img {
    width: 150px;
  }
}

// React Table Css
.table.react-table {
  & tbody {
    background-color: $card-background-color;
  }

  & th,
  td {
    border: none;
  }

  & td {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    vertical-align: middle;
  }
}

// link underline

.sign-in-link {
  color: $dark-green;
  border-bottom: 1px solid $dark-green;

  &:hover {
    color: $dark-green;
    border-bottom: 1px solid $dark-green;
  }
}

.text-under-line {
  text-decoration-line: underline;
}

body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.25);
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

.font-weight-100 {
  font-weight: 100;
}

body[data-layout-size="boxed"] #layout-wrapper {
  box-shadow: none;
  background-color: #f6f6f6;
}

.job-detail-card .skill-container {
  padding: 5px 10px;
  border-radius: 45px;
  border: 1px solid #c4c4c4;
  position: relative;
  color: $white;
  background-color: #74b496;
  font-weight: 300;
  font-size: 14px;
}

.dropdown-menu {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0px 4px 4px;

  a {
    color: $light-green;
  }
}

.cut-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.route_soon {
  position: absolute;
  font-size: 10px;
  bottom: 5px;
  left: 0;
}

.link-forgot-password {
  color: #213d30 !important;
  font-weight: 600;
}

// @media (min-width: 1600px) {
//     .top-container .ux-designer{
//         padding-top: 2.6% !important;
//         padding-left: 9.2% !important;
//     }
//  }
.font-size-27 {
  font-size: 27px;
}

.courses-cert-img {
  width: 55px;
  position: relative;
}

.apply_resume_wrapper {
  .company-logo-img {
    width: 60px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    position: relative;

    img {
      width: 40px;
      height: 40px;
    }

    .img-close {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: #eb5757;
      border-radius: 100%;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      position: absolute;
      right: -8px;
      top: -5px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
}

.select-container.multiple-select .css-1rhbuit-multiValue {
  background-color: #74b496;
  border-radius: 12px;
  color: #fff;
}

.select-container.multiple-select .css-1rhbuit-multiValue .css-12jo7m5 {
  color: #fff;
}

.select-container.multiple-select .css-1okebmr-indicatorSeparator {
  background-color: transparent;
}

.select-container.multiple-select .css-1gtu0rj-indicatorContainer {
  background-image: none;
  color: transparent;
}

.select-container.multiple-select .css-tlfecz-indicatorContainer {
  background-color: transparent;
}

.select-container.multiple-select .css-xb97g8:hover {
  background-color: transparent;
  color: #ffffff;
}

.notification_container {
  .dropdown-menu a {
    &:hover {
      color: #74b496;
    }
  }

  .btn:focus {
    box-shadow: none;
  }
}

.web_display {
  display: flex;
}

.btn-primary {
  background-color: $dark-green;
  border-color: $dark-green;
}

.upload {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mobileDevice {
  .table-responsive {
    background-color: #fff;
  }

  .mobile_margin_5 {
    margin-top: 2em;
  }

  .navbar-header {
    .logo.logo-dark img {
      width: 120px;
    }

    padding-left: 12px;
    padding-right: 0;

    .navbar-brand-box {
      padding: 0;
    }

    .header-font .btn {
      padding: 0.47rem 0.7rem;
    }
  }

  .account-pages {
    .right-section {
      display: none;
    }

    .left-section {
      width: 100%;
      padding: 20px 0px;
    }

    .login-heading {
      margin-left: 0;
    }

    .login-form {
      margin-left: 0;
    }

    .form_wrapper {
      padding-left: 0 !important;
    }
  }

  .header_down_arrow {
    font-size: 1.5em;
  }

  .route_soon {
    position: relative;
  }

  .job-side-container.card {
    margin: 15px !important;
  }

  .mobile_right_padding {
    padding-right: 12px !important;
  }

  .mobile_margin {
    margin-left: 1rem !important;
  }

  .mobile_margin_5 {
    margin-top: 2em;
  }

  .mobile_jobdetail_status_text {
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
    margin-left: 3rem !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .job-details {
    .mobile_job_detail_header {
      margin-bottom: 1rem !important;
      padding-right: 0 !important;
    }
  }

  .web_display {
    display: block;
  }

  .mob_display {
    display: flex;
  }

  .mobile_apply_btn {
    display: flex;
    align-items: center;
  }

  .jobs-cont .top-container .company-info {
    min-height: 110px;
  }

  .profile-page {
    padding-left: 15px;
    padding-right: 15px;
  }

  .account-setting {
    margin-top: 90px !important;

    .mobile_padding {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .top-container .company-info {
      height: auto;
      min-height: 60px;
    }

    .mobile_account_header {
      margin-bottom: 1rem !important;
    }

    .top-container .ux-designer {
      padding-top: 0.7em;
    }
  }

  .account-setting .top-container .company-info {
    min-height: 50px;
  }

  // .resume-container {
  //   width: 760px;
  // }

  .resume_builder_page .container-fluid {
    overflow-x: auto;
  }

  .profile-page .nav-tabs .nav-item .nav-link {
    padding: 0.5rem;
  }

  .profile-page .nav-tabs .nav-item {
    height: 39px;
  }

  .twitter-bs-wizard
    .twitter-bs-wizard-nav
    .nav-item
    .nav-link
    .step-number::before {
    left: 65%;
    width: 71%;
  }

  .box-personal {
    min-width: 100%;
  }

  .bg-verification-modal .modal-body {
    padding: 1rem;
  }

  .bg-verification-modal .bg_verfication_top_mobile {
    margin-top: 1rem !important;
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: block;
  }

  .w-lg {
    min-width: 120px;
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] .page-content {
    margin-top: 70px;
  }
}

.account-setting .top-container .shift-image {
  background: #fff;
  padding: 0.5em;
}

.toast-top-right .toast-success {
  border: 2px solid #34c38f !important;
  background-color: #34c38f !important;
}

.modal-title {
  color: #213d30;
  font-size: 1.25rem;
  font-weight: normal;
}

.modal-dialog .modal-title {
  font-size: 1.5em;
}

.modal-dialog .close {
  opacity: 1;
}

.modal-dialog .close span {
  font-size: 1.7em;
  font-weight: 100;
  color: #111111;
}

.modal-dialog .modal-header {
  align-items: center;
}

.btn-lg {
  min-width: 140px;
}

.btn-outline-shadow span {
  color: #447e63;
}

.header-profile-user {
  height: 27px;
  width: 27px;
  background-color: #fff;
  padding: 3px;
  margin-bottom: 7px;
}

.main-content h6 {
  font-size: 1.5em;
}

.about_heading_text {
  color: $card-company-text-color;
}

.count_color {
  color: #7e8384;
  opacity: 0.5;
}

@font-face {
  font-family: "Helvetica";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

body {
  font-family: "Helvetica";
}

.edit-bio-modal .form-group label {
  color: #213d30;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

input[type="checkbox"] {
  visibility: hidden;
}

.main {
  margin-left: 0.5rem;
}

.geekmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #eeeeee;
  border-radius: 4px;
}

.main input:checked ~ .geekmark {
  background-color: $light-green;
  border: none;
}

.main input:checked ~ .geekmark:after {
  display: block;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

.main .geekmark:after {
  left: 8px;
  bottom: 6px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(52deg);
}

.tab-pane.educational-details .accordian-container .btn:disabled {
  opacity: 0.3;
}

.custom_skill_level {
  background-color: $white;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 0px;
  padding: 4px 8px;
  border-radius: 15px;
  color: #74b496;
  font-weight: 100;
  border-color: $white;
}

.custom_skill_level_highlight {
  background-color: #74b496;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 0px;
  padding: 4px 8px;
  border-radius: 15px;
  color: $white;
  font-weight: 100;
  border-color: #74b496;
}

.candidature {
  & .dropzone {
    width: 36px;
    min-height: auto;
    border: none;

    & .dz-message {
      padding: 0px;
    }

    & img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .bg-cancel,
  .waves-effect {
    padding: 0.5rem;
  }
  body .btn-lg {
    min-width: 130px;
  }
  body .top-container .ux-designer {
    height: auto;
  }
  body.mobileDevice .jobs-cont .top-container .company-info {
    min-height: 130px;
  }
  .mobile_job_detail_header {
    flex-flow: row wrap;
  }
  .mobileDevice .mob_display {
    font-size: 12px;
  }
  .cut-text-ellipsis img {
    padding-left: 0 !important;
  }
  body.mobileDevice .mobile_jobdetail_status_text {
    margin-left: 2rem !important;
  }
}

@media screen and (max-width: 420px) {
  .mobile-resume-builder {
    flex: 1 1 100% !important;
  }
}
