.box-200{
    padding: 1rem;
    background: #FFFFFF;
    // width: 275px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    &:hover{
        box-shadow: 0px 2px 14px rgba(140, 140, 140, 0.16);
        transform: scale(1.1);
    }
}
.company-logos{
    width: 80px;
    height: 80px;
    border-radius: 50%;
  border: 1px solid #E5E5E5;
  padding: 4px;
    background: #FFFFFF;
    object-fit: contain;
}

.black-border{
    width: 100%;
    margin: auto;
    border-top: 1px solid gray;
    opacity: 0.4;
   
}
.rem-link-def{
    text-decoration: none;
    color:$dark-green;
}
.rem-link-def:hover{
    color:$dark-green;
}
.light-black-text{
    color:$card-company-text-color;
}
